import { Fragment, type ReactNode } from 'react';

type ComponentReplacementProps = {
  componentReplacements: Record<string, ( children: string ) => ReactNode>;
  children: string;
}

export const ComponentReplacement = ( { children, componentReplacements }: ComponentReplacementProps ) => {
  const text = children.split( /(\[.*?\])/g );

  return (
    <>
      { text.map( ( text, index ) => {
        const Component = componentReplacements[ text.replace( /\[|\]/g, '' ) ];
        if ( Component ) {
          return (
            <Fragment key={ index }>
              { Component( text ) }
            </Fragment>
          )
        }

        return (
          <Fragment key={ index }>
            { text }
          </Fragment>
        )
      } ) }
    </>
  )
}
